
import {
  useRoute
} from 'vue-router'
import HeaderCardDetail from '@/views/master/customers/customer/customer-company/detail/HeaderCardDetail.vue'
import AlamatPerusahaan from '@/views/master/customers/customer/customer-company/detail/AlamatPerusahaan.vue'

export default {
  name: 'DetailPelangganCompany',
  components: {
    HeaderCardDetail,
    AlamatPerusahaan
  },
  setup() {
    const route = useRoute()
    const getDecriptId = () => route.params.idCompany
    return {
      getDecriptId,
      route
    }
  }

}
