import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  useRouter,
  useRoute
} from 'vue-router'
import {
  customerCompanyUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'

export default {
  name: 'HeaderCardDetail',
  props: {
    Id: null
  },
  setup(props: any) {
    const router = useRouter()
    const route = useRoute()
    const op = ref()
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()

    const dataCustomerCompany: any = ref({})
    const getDecriptId = () => route.params.idCompany
    const getDataCustomerCompany = async () => {
      const response = await customerCompanyUseCase.getDataForm(props.Id)
      console.log(response)
      dataCustomerCompany.value = response.data.result
      dataCustomerCompany.value.NamaKota = response.data.result.Kota.Nama
    }
    const redirectEdit = (currentId: any) => {
      router.push({
        name: 'master-pelanggan-perusahaan-edit',
        params: {
          idUser: currentId
        }
      })
    }

    const redirectMaster = () => {
      router.push({
        name: 'master-pelanggan',
      })
    }

    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const dataForm = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await customerCompanyUseCase.changeIsActive(dataForm)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorChangeStatus,
          group: 'bc',
          life: 1500
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const deleteData = (Id: any) => {
      $confirm.require({
        header: 'Pelanggan Perusahaan',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await customerCompanyUseCase.deleteData(Id)
          // console.log('reposne delete', response)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: 'Gagal menghapus pelanggan perusahaan, silahkan coba kembali',
              group: 'bc',
              life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            // getDataCustomerCompany()
            redirectMaster()
          }
        }
      })
    }

    const showDropDown = (evt: any) => {
      // console.log(evt)
      op.value.toggle(evt)
    }

    onMounted(() => {
      getDataCustomerCompany()
    })
    return {
      router,
      route,
      getDecriptId,
      dataCustomerCompany,
      getDataCustomerCompany,
      op,
      showDropDown,
      app,
      store,
      redirectEdit,
      changeIsActive,
      isActived,
      deleteData
    }
  }
}
