import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import {
  customerCompanyAddressUseCase
} from '@/domain/usecase'
import AlamatPerusahaanForm from '@/views/master/customers/customer/customer-company/detail/AlamatPerusahaanForm.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'AlamatPerusahaan',
  components: {
    AlamatPerusahaanForm
  },
  props: {
    Id: null
  },
  setup(props: any) {
    const store = useStore()
    const filter = {
      totalRecords: 0,
      top: 10,
      skip: 0
    }
    const propertiesProps = reactive(props)
    const sliderActive = ref(false)
    const inputSearch = ref(null)
    const sendInputSearch = ref('')
    const dataForm = ref(null)
    const dataAddress = ref([])
    const app = getCurrentInstance()
    const op = ref()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const route = useRoute()

    const getDataAddress = async () => {
      const response = await customerCompanyAddressUseCase.getAll({
        skip: filter.skip,
        top: filter.top,
        search: sendInputSearch.value,
        idCompany: propertiesProps.Id
      })
      dataAddress.value = response.result
      filter.totalRecords = response.count
      // console.log(response)
    }
    const onPage = (event: any) => {
      filter.skip = event.page * filter.top
      getDataAddress()
    }
    const searchData = (val: any) => {
      sendInputSearch.value = val.toUpperCase()
      getDataAddress()
    }
    const showDropDown = (evt: any, val: any) => {
      dataForm.value = val
      op.value.toggle(evt)
    }
    const deleteData = (val: number) => {
      $confirm.require({
        header: 'Alamat Pengiriman',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await customerCompanyAddressUseCase.deleteData(val)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: $strInd.toast.successDeleteData,
              group: 'bc',
              life: 3000
            })
            getDataAddress()
          }
        }
      })
    }
    const closeSidebar = () => {
      sliderActive.value = false
    }
    const redirectAdd = () => {
      dataForm.value = null
      sliderActive.value = true
    }
    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const dataList = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await customerCompanyAddressUseCase.changeIsActive(dataList)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }
    const editData = (val: any) => {
      // console.log('data edit', val)
      dataForm.value = val
      sliderActive.value = true
    }
    onMounted(() => {
      getDataAddress()
    })
    return {
      filter,
      sliderActive,
      inputSearch,
      sendInputSearch,
      onPage,
      searchData,
      dataAddress,
      app,
      op,
      showDropDown,
      deleteData,
      closeSidebar,
      redirectAdd,
      editData,
      dataForm,
      propertiesProps,
      route,
      getDataAddress,
      changeIsActive
    }
  }
}
