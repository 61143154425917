import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "detail-pelanggan-company" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderCardDetail = _resolveComponent("HeaderCardDetail")
  const _component_AlamatPerusahaan = _resolveComponent("AlamatPerusahaan")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode(_component_HeaderCardDetail, {
      Id: $setup.getDecriptId()
    }, null, 8, ["Id"]),
    _createVNode(_component_AlamatPerusahaan, {
      Id: $setup.getDecriptId()
    }, null, 8, ["Id"])
  ]))
}